<template>
  <app-modal-transaction-detail :show="showModalDetail" @close="showModalDetail = false"
    :transaction="selectTransaction" @update:transaction="selectTransaction = $event"
    routeName="balance.transaction.payment-link" />
  <app-table :apiResponse="apiPaginationResponse?.data ?? []" :loading="apiPaginationLoading" :showEmptyButton="false" :filters="
    this.isMasterBusinessRole
      ? [
        {
          key: 'filter[business_id]',
          type: 'select',
          placeholder: $t('business.table.header_businss_name'),
          items: all_business,
        },
        {
          key: 'filter[id]',
          type: 'text',
          placeholder: 'ID',
          value: '',
        },
        {
          key: 'filter[amount]',
          type: 'text',
          placeholder: 'Amount',
          value: '',
        },
        {
          key: 'filter[type]',
          type: 'select',
          placeholder: 'Type',
          items: [
            {
              id: 'transfer_in',
              name: $t('wallet.transaction.type_transfer_in'),
            },
            {
              id: 'transfer_out',
              name: $t('wallet.transaction.type_transfer_out'),
            },
          ],
        },
        {
          key: 'filter[created_at_between]',
          type: 'date',
          placeholder: 'Date',
          value: '',
        },
      ]
      : [
        {
          key: 'filter[id]',
          type: 'text',
          placeholder: 'ID',
          value: '',
        },
        {
          key: 'filter[amount]',
          type: 'text',
          placeholder: 'Amount',
          value: '',
        },
        {
          key: 'filter[type]',
          type: 'select',
          placeholder: 'Type',
          items: [
            {
              id: 'transfer_in',
              name: $t('wallet.transaction.type_transfer_in'),
            },
            {
              id: 'transfer_out',
              name: $t('wallet.transaction.type_transfer_out'),
            },
          ],
        },
        {
          key: 'filter[created_at_between]',
          type: 'date',
          placeholder: 'Date',
          value: '',
        },
      ]
  " @params-changed="paramsChanged" @on-item-click="onItemClick">
    <template v-slot:title>
      <div>
        <p class="font-medium">
          {{ $t("wallet.transaction.transactions") }}
        </p>
      </div>
    </template>
    <template v-slot:header>
      <th class="font-bold">{{ $t("wallet.transaction.date") }}</th>
      <th v-if="this.isMasterBusinessRole" class="font-bold">
        {{ $t("wallet.transaction.business_name") }}
      </th>
      <th class="font-bold">{{ $t("wallet.transaction.description") }}</th>
      <th class="font-bold">{{ $t("wallet.transaction.type") }}</th>
      <th class="font-bold">{{ $t("wallet.transaction.amount") }}</th>
    </template>
    <template v-slot:body="slotData">
      <td>
        {{ $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a") }}
      </td>
      <td v-if="this.isMasterBusinessRole">
        {{ slotData.model.business.name }}
      </td>
      <td>
        <p class="capitalize">
          {{ slotData.model.description }}
        </p>
      </td>
      <td>
        <p class="capitalize">
          {{ slotData.model.type?.replace("_", " ") }}
        </p>
      </td>
      <td class="flex place-items-center space-x-2 justify-between">
        <p class="font-medium" :class="{
          'text-success': slotData.model.type?.includes('_in'),
          'text-error': slotData.model.type?.includes('_out'),
        }">
          {{
              $formats.currency(
                slotData.model.currency,
                slotData.model.amount ?? 0.0
              )
          }}
        </p>
      </td>
    </template>
  </app-table>
</template>
<script>
import BUSINESS_STATUS from "@/utils/const/business_status";

export default {
  data() {
    return {
      filters: "",
      showModalDetail: false,
      selectTransaction: null
    };
  },

  mounted() {
    this.$store.dispatch(
      "walletStore/retrieveWalletTransactions",
      this.filters
    );
    this.$store.dispatch("masterBusinessStore/getMasterBusiness", {
      business_status_id: BUSINESS_STATUS.APPROVED,
    });
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["walletStore/apiPaginationResponse"];
    },

    apiPaginationLoading() {
      return this.$store.getters["walletStore/apiPaginationLoading"];
    },

    isMasterBusinessRole() {
      return this.$store.getters["authStore/isMasterBusinessRole"]
    },

    all_business() {
      return this.$store.getters["masterBusinessStore/all_business"];
    },
  },

  methods: {
    paramsChanged(filters, queryParams) {
      this.filters = filters;
      if (this.isMasterBusinessRole) {
        this.filters["business_id"] = filters["filter[business_id]"]?.id;
        this.filters["filter[business_id]"] = this.filters["business_id"];
      }
      this.$store.dispatch(
        "walletStore/retrieveWalletTransactions",
        this.filters
      );
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectTransaction = data;
    },
  },
};
</script>
